import React from 'react';

import './style.css';
import Banner from '../../Components/banner';
import Solutions from '../../Components/solutions';

function Home() {
  return <div className='home_wrapper'>
    <div className='home_container'>
        <Banner/>
        <Solutions/>
    </div>
  </div>;
}

export default Home;