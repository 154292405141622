import React from "react";

import "./style.css";
import BannerMail from "../../Components/bannermail";
import SolutionsMail from "../../Components/solutionsmail";
import Clients from "../../Components/clients";
function Mail() {
  return (
    <div className="mail_wrapper">
      <div className="mail_container">
        <BannerMail />
        <SolutionsMail />
        <Clients />
      </div>
    </div>
  );
}

export default Mail;
