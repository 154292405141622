import React from "react";

import "./style.css";
import Headline from "../../Components/Headline";
import InfoAmbiente from "../../Components/InfoAmbiente";

function Ambiente() {
  return (
    <div className="ambiente_wrapper">
      <Headline />
      <InfoAmbiente />
    </div>
  );
}

export default Ambiente;
