import React from "react";

import "./style.css";
import Reveal from "../Reveal";
function Headline() {
  return (
    <div className="headline_wrapper">
      <div className="headline_container">
        <Reveal
          delay="0.2"
          component={<div className="detail_title">ambiente misto</div>}
        ></Reveal>
        <div className="headline">
          <Reveal
            delay="0.4"
            component={
              <>
                <strong>Maximize a eficiência</strong> e reduza os custos da sua
                empresa <strong>em até 70%</strong> com o ambiente misto da
                Meile.
              </>
            }
          ></Reveal>
        </div>

        <div className="subheadline">
          <Reveal
            delay="0.6"
            component={
              <>
                Nossa solução integra perfeitamente nossos serviços com os
                produtos líderes do mercado,{" "}
                <strong>Google e Microsoft,</strong> proporcionando uma
                experiência unificada e otimizada.
              </>
            }
          ></Reveal>
        </div>
      </div>
    </div>
  );
}

export default Headline;
