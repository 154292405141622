import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Home from "./Pages/Home";
import Mail from "./Pages/Mail";
import ErrorPage from "./Pages/Error";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Ambiente from "./Pages/Ambiente";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
        errorElement: <ErrorPage />,
      },
      {
        path: "mail",
        element: <Mail />,
      },
      {
        path: "ambientemisto",
        element: <Ambiente />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <div className="wrapall">
      <RouterProvider router={router} />
    </div>
    <div id="bg-wrap">
      <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
        <defs>
          <radialGradient
            id="Gradient1"
            cx="50%"
            cy="50%"
            fx="0.441602%"
            fy="50%"
            r=".5"
          >
            <animate
              attributeName="fx"
              dur="45s"
              values="0%;3%;0%"
              repeatCount="indefinite"
            ></animate>
            <stop offset="0%" stopColor="#1F5AF2"></stop>
            <stop offset="100%" stopColor="rgba(0, 0, 0, 0)"></stop>
          </radialGradient>
          <radialGradient
            id="Gradient2"
            cx="50%"
            cy="50%"
            fx="2.68147%"
            fy="50%"
            r=".5"
          >
            <animate
              attributeName="fx"
              dur="55s"
              values="0%;3%;0%"
              repeatCount="indefinite"
            ></animate>
            <stop offset="0%" stopColor="#F2411F"></stop>
            <stop offset="100%" stopColor="rgba(0, 0, 0, 0)"></stop>
          </radialGradient>
          <radialGradient
            id="Gradient3"
            cx="50%"
            cy="50%"
            fx="0.836536%"
            fy="50%"
            r=".5"
          >
            <animate
              attributeName="fx"
              dur="60s"
              values="0%;3%;0%"
              repeatCount="indefinite"
            ></animate>
            <stop offset="0%" stopColor="#1F5AF2"></stop>
            <stop offset="100%" stopColor="rgba(0, 0, 0, 0)"></stop>
          </radialGradient>
          <radialGradient
            id="Gradient4"
            cx="50%"
            cy="50%"
            fx="4.56417%"
            fy="50%"
            r=".5"
          >
            <animate
              attributeName="fx"
              dur="60s"
              values="0%;5%;0%"
              repeatCount="indefinite"
            ></animate>
            <stop offset="0%" stopColor="#1F5AF2"></stop>
            <stop offset="100%" stopColor="rgba(0, 255, 0, 0)"></stop>
          </radialGradient>
          <radialGradient
            id="Gradient5"
            cx="50%"
            cy="50%"
            fx="2.65405%"
            fy="50%"
            r=".5"
          >
            <animate
              attributeName="fx"
              dur="50s"
              values="0%;5%;0%"
              repeatCount="indefinite"
            ></animate>
            <stop offset="0%" stopColor="#1F5AF2"></stop>
            <stop offset="100%" stopColor="rgba(0,0,255, 0)"></stop>
          </radialGradient>
          <radialGradient
            id="Gradient6"
            cx="50%"
            cy="50%"
            fx="0.981338%"
            fy="50%"
            r=".5"
          >
            <animate
              attributeName="fx"
              dur="40s"
              values="0%;5%;0%"
              repeatCount="indefinite"
            ></animate>
            <stop offset="0%" stopColor="rgba(255,0,0, 1)"></stop>
            <stop offset="100%" stopColor="rgba(255,0,0, 0)"></stop>
          </radialGradient>
        </defs>
        <rect
          x="13.744%"
          y="1.18473%"
          width="100%"
          height="100%"
          fill="url(#Gradient1)"
          transform="rotate(334.41 50 50)"
        >
          <animate
            attributeName="x"
            dur="20s"
            values="25%;0%;25%"
            repeatCount="indefinite"
          ></animate>
          <animate
            attributeName="y"
            dur="21s"
            values="0%;25%;0%"
            repeatCount="indefinite"
          ></animate>
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 50 50"
            to="360 50 50"
            dur="7s"
            repeatCount="indefinite"
          ></animateTransform>
        </rect>
        <rect
          x="-2.17916%"
          y="35.4267%"
          width="100%"
          height="100%"
          fill="url(#Gradient2)"
          transform="rotate(255.072 50 50)"
        >
          <animate
            attributeName="x"
            dur="23s"
            values="-25%;0%;-25%"
            repeatCount="indefinite"
          ></animate>
          <animate
            attributeName="y"
            dur="24s"
            values="0%;50%;0%"
            repeatCount="indefinite"
          ></animate>
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 50 50"
            to="360 50 50"
            dur="12s"
            repeatCount="indefinite"
          ></animateTransform>
        </rect>
        <rect
          x="9.00483%"
          y="14.5733%"
          width="100%"
          height="100%"
          fill="url(#Gradient3)"
          transform="rotate(139.903 50 50)"
        >
          <animate
            attributeName="x"
            dur="90s"
            values="0%;25%;0%"
            repeatCount="indefinite"
          ></animate>
          <animate
            attributeName="y"
            dur="100s"
            values="0%;25%;0%"
            repeatCount="indefinite"
          ></animate>
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="360 50 50"
            to="0 50 50"
            dur="120s"
            repeatCount="indefinite"
          ></animateTransform>
        </rect>
      </svg>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
