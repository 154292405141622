import React, { useEffect } from "react";
import $ from "jquery";
import "./style.css";
import logo from "../../Assets/img/MEILE_2024_MARCA-03.svg";
import drag from "../../Assets/img/drag.svg";
import Reveal from "../Reveal";
import { Link } from "react-router-dom";
function Menu() {
  useEffect(() => {
    $(document).ready(function ($) {
      $(window).scroll(function () {
        if ($(window).scrollTop() >= 20) {
          $(".menu_wrapper").addClass("active");
        } else {
          $(".menu_wrapper").removeClass("active");
        }
      });
    });
  }, []);

  useEffect(() => {
    $(document).ready(function ($) {
      $(window).scroll(function () {
        $(".drag_icon").on("click", function () {
          $(".menu_hide").toggle();
        });
      });
    });
  }, []);

  return (
    <div className="menu_wrapper">
      <div className="menu_container">
        <div className="logo">
          <Reveal
            delay="0.2"
            component={
              <Link to={"/"}>
                <img src={logo} alt="logo" />
              </Link>
            }
          />
          <div className="nav">
            <Reveal
              delay="0.4"
              classe={"navop"}
              component={
                <Link to={"https://meile.com.br/"}>
                  <div>pagina inicial</div>
                </Link>
              }
            />
            <Reveal
              delay="0.6"
              classe={"navop"}
              component={
                <div className="menu_drop">
                  <div className="menu-title menu-title_2nd">
                    <Link to={"/"}>
                      <span>Produtos</span>
                    </Link>
                    <ul className="menu-dropdown">
                      <Link to={"/mail"}>
                        <li>meile mail</li>
                      </Link>
                      <Link to={"/ambientemisto"}>
                        <li>ambiente misto</li>
                      </Link>
                    </ul>
                  </div>
                </div>
              }
            />
            <Reveal
              delay="0.8"
              classe={"navop"}
              component={
                <Link to={"https://partner.meile.com.br/"}>
                  <div>Partner </div>
                </Link>
              }
            />

            <Reveal
              delay="1"
              classe={"navop"}
              component={
                <Link to={"/"}>
                  <div>Sobre nós </div>
                </Link>
              }
            />

            <Reveal
              delay="1.2"
              classe={"navop"}
              component={
                <Link
                  to={
                    "https://clientes.mav.com.br/identificacao/login?redirect=%2F"
                  }
                >
                  <div>Portal do cliente </div>
                </Link>
              }
            />
          </div>
        </div>
        <div className="drag_menu_container">
          <div className="drag_icon">
            <img src={drag} alt="menu" />
          </div>
        </div>
      </div>

      <div className="menu_hide">
        <Reveal
          delay="0.4"
          classe={"navop"}
          component={
            <Link to={"https://meile.com.br/"}>
              <div>pagina inicial</div>
            </Link>
          }
        />
        <Reveal
          delay="0.6"
          classe={"navop"}
          component={
            <div className="menu_drop">
              <div className="menu-title menu-title_2nd">
                <Link to={"/"}>
                  <span>Produtos</span>
                </Link>
                <ul className="menu-dropdown">
                  <Link to={"/mail"}>
                    <li>meile mail</li>
                  </Link>
                  <Link to={"/ambientemisto"}>
                    <li>ambiente misto</li>
                  </Link>
                </ul>
              </div>
            </div>
          }
        />
        <Reveal
          delay="0.8"
          classe={"navop"}
          component={
            <Link to={"https://partner.meile.com.br/"}>
              <div>Partner </div>
            </Link>
          }
        />

        <Reveal
          delay="1"
          classe={"navop"}
          component={
            <Link to={"/"}>
              <div>Sobre nós </div>
            </Link>
          }
        />

        <Reveal
          delay="1.2"
          classe={"navop"}
          component={
            <Link
              to={
                "https://clientes.mav.com.br/identificacao/login?redirect=%2F"
              }
            >
              <div>Portal do cliente </div>
            </Link>
          }
        />
      </div>
    </div>
  );
}

export default Menu;
