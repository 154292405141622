import "./App.css";
import Footer from "./Components/footer";
import Menu from "./Components/menu";
import Whatsapp from "./Components/whatsapp";

import { Outlet } from "react-router-dom";
function App() {
  return (
    <div className="App">
      <Menu />
      <Outlet />
      <Footer />
      <Whatsapp/>
    </div>
  );
}

export default App;
