import { useRouteError, Link } from "react-router-dom";
import "./style.css";
export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <div className="info_error">
        <h1>Oops!</h1>
        <p>Desculpe, ocorreu um erro inesperado.</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
      </div>
      <Link to={"/"}>ir para pagina inicial</Link>
    </div>
  );
}
