import React from "react";
import Reveal from "../Reveal";
import "./style.css";
import bimg from "../../Assets/img/benefits.svg";
function InfoAmbiente() {
  return (
    <div className="info_ambeinte_wrapper">
      <div className="info_ambiente_container">
        <Reveal
          delay="0.6"
          component={
            <>
              <h1>O que é o Ambiente Misto Meile?</h1>
            </>
          }
        ></Reveal>
        <div className="subheadline">
          <Reveal
            delay="0.6"
            component={
              <>
                O Ambiente Misto da Meile combina o melhor de dois mundos.
                Unimos nossas soluções de comunicação com os serviços avançados
                do Google e Microsoft, permitindo que sua empresa desfrute de
                uma infraestrutura robusta e flexível, sem complicações e com
                custos reduzidos.
              </>
            }
          ></Reveal>
        </div>

        <div className="info_benefits_container">
          <div className="info_benefits_left">
            <Reveal
              delay="0.6"
              component={<h2>Benefícios do Ambiente Misto Meile:</h2>}
            ></Reveal>

            <div className="benefits">
              <div className="box_benefits">
                <Reveal
                  delay="0.6"
                  component={<span>Redução significativa de custos:</span>}
                ></Reveal>

                <Reveal
                  delay="0.6"
                  component={
                    <p>
                      Economize até 70% integrando nossos serviços com Google
                      Workspace e Microsoft 365, otimizando recursos de forma
                      acessível.
                    </p>
                  }
                ></Reveal>
              </div>

              <div className="box_benefits">
                <Reveal
                  delay="0.6"
                  component={<span>Segurança e confiabilidade:</span>}
                ></Reveal>
                <Reveal
                  delay="0.6"
                  component={
                    <p>
                      Aproveite a segurança robusta e a alta disponibilidade de
                      nossos serviços, aliados à confiabilidade dos gigantes da
                      tecnologia.
                    </p>
                  }
                ></Reveal>
              </div>

              <div className="box_benefits">
                <Reveal
                  delay="0.6"
                  component={<span>Suporte dedicado:</span>}
                ></Reveal>
                <Reveal
                  delay="0.6"
                  component={
                    <p>
                      Conte com nossa equipe de especialistas para auxiliar na
                      integração e fornecer suporte contínuo, garantindo que
                      tudo funcione perfeitamente.
                    </p>
                  }
                ></Reveal>
              </div>
            </div>
          </div>

          <div className="info_benefits_right">
            <Reveal
              delay="0.6"
              component={<img src={bimg} alt="banner" />}
            ></Reveal>
          </div>
        </div>

        <div className="como_funciona">
          <Reveal delay="0.6" component={<h2>Como funciona? </h2>}></Reveal>
          <Reveal
            delay="0.6"
            component={
              <p>
                No Ambiente Misto da Meile, as contas de e-mail mais importantes
                permanecem nos serviços atuais –
                <strong>Google ou Microsoft.</strong> Ao mesmo tempo, as contas
                adicionais são transferidas para a Meile, onde oferecemos uma
                solução <strong>totalmente integrada.</strong> Nossa integração,
                cuidadosamente projetada, garante uma experiência contínua e
                eficiente para seus usuários, independentemente da plataforma
                utilizada.
              </p>
            }
          ></Reveal>
        </div>

        <div className="como_funciona">
          <Reveal delay="0.6" component={<h2>Recursos Meile </h2>}></Reveal>
          <Reveal
            delay="0.6"
            component={
              <p>
                Além dos benefícios da
                <strong> integração do ambiente misto,</strong> a Meile oferece
                recursos exclusivos para as contas hospedadas conosco. Isso
                inclui arquivamento de todas as mensagens por até 10 anos,
                proteção antispam premium,{" "}
                <strong>controles avançados de acesso</strong> e recibo de
                entrega, garantindo mais segurança e confiabilidade para suas
                comunicações e muito mais!
              </p>
            }
          ></Reveal>
        </div>

        <div className="get_in_touch_container">
          <p>Descubra o melhor dos dois mundos.</p>
          <a
            href="https://api.whatsapp.com/send?phone=553132117760&text=Olá,%20quero%20ser%20um%20Partner!%20"
            target="_blank"
            rel="noreferrer"
          >
            <div className="btn">Agende uma apresentação hoje mesmo!</div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default InfoAmbiente;
