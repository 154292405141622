import React from "react";

import "./style.css";
import bimg from "../../Assets/img/banner_image.svg";
import zig from "../../Assets/img/zig.svg";
import Reveal from "../Reveal";
function Banner() {
  return (
    <div className="banner_wrapper" id="home">
      <div className="banner_container">
        <div className="banner_info_left">
          <Reveal
            delay="0.4"
            component={
              <div className="banner_headline">
                Descubra <span>soluções eficientes</span>, seguras e{" "}
                <span>
                  personalizadas <img src={zig} alt="banner" />
                </span>{" "}
                para o seu negócio.
              </div>
            }
          />
        </div>
        <div className="banner_info_right">
          <Reveal delay="1" component={<img src={bimg} alt="banner" />} />
        </div>
      </div>
    </div>
  );
}

export default Banner;
