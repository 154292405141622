import React from "react";

import "./style.css";
import bimg from "../../Assets/img/bimgmail.svg";
import zig from "../../Assets/img/zig.svg";
import Reveal from "../Reveal";
function BannerMail() {
  return (
    <div className="mail_banner_wrapper" id="home">
      <div className="mail_banner_container">
        <div className="mail_banner_info_left">
          <Reveal
            delay="0.4"
            component={
              <div className="mail_banner_headline">
                <span>
                  Meile Mail <img src={zig} alt="banner" />
                </span>
              </div>
            }
          />
          <Reveal
            delay="0.4"
            component={
              <div className="mail_banner_subheadline">
                <p>
                  Na Meile, compreendemos a importância da comunicação eficaz
                  para o sucesso de qualquer negócio. É por isso que oferecemos
                  o Meile Mail, uma solução completa de e-mail corporativo
                  projetada para atender às necessidades das empresas modernas.
                </p>
                <p>
                  Estamos na vanguarda do desenvolvimento, criando nossos
                  próprios produtos e oferecendo as melhores soluções com os
                  preços mais competitivos do mercado.
                </p>
              </div>
            }
          />
        </div>
        <div className="mail_banner_info_right">
          <Reveal delay="1" component={<img src={bimg} alt="banner" />} />
        </div>
      </div>
    </div>
  );
}

export default BannerMail;
