import React from "react";
import "./style.css";
import simg from "../../Assets/img/solutions.svg";
import { Link } from "react-router-dom";

import Reveal from "../Reveal";
function Solutions() {
  return (
    <div className="solution_wrapper">
      <div className="solution_container">
        <div className="solution_headline_wrapper">
          <Reveal delay="0.4" component={<h1>Nossas soluções</h1>} />
        </div>
        <div className="solution_subheadline_wrapper">
          <div className="left">
            <Reveal
              delay="0.4"
              component={
                <>
                  Na nossa página de produtos, você encontrará soluções que não
                  apenas atendem aos padrões mais elevados, mas também se
                  encaixam no seu orçamento. Sua excelência começa aqui!
                </>
              }
            />
          </div>
          <div className="right">
            <Reveal
              delay="0.4"
              component={
                <>
                  Apresentamos nossa solução de hospedagem web e cloud, explore
                  nossos produtos e leve sua empresa para o próximo nível.
                </>
              }
            />
          </div>
        </div>

        <div className="products_wrapper">
          <div className="image_products">
            <Reveal
              delay="0.4"
              component={<img src={simg} alt="solutions" />}
            />
          </div>

          <div className="box_products">
            <div className="box">
              <Reveal
                delay="0.4"
                component={
                  <>
                    <Link to={"/mail"}>
                      <svg
                        width="22"
                        height="20"
                        viewBox="0 0 22 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.40221 19.2505L0.127197 17.2086L15.7272 3.20871H1.75221V0.292053H21.2523V17.7921H18.0022V5.25037L2.40221 19.2505Z"
                          fill="white"
                        />
                      </svg>
                      <span>Meile Mail</span> Hospedagem completa de e-mail
                      profissional.
                    </Link>
                  </>
                }
              />
            </div>

            <div className="box">
              <Reveal
                delay="0.4"
                component={
                  <>
                    <Link to={"/ambientemisto"}>
                      <svg
                        width="22"
                        height="20"
                        viewBox="0 0 22 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.40221 19.2505L0.127197 17.2086L15.7272 3.20871H1.75221V0.292053H21.2523V17.7921H18.0022V5.25037L2.40221 19.2505Z"
                          fill="white"
                        />
                      </svg>
                      <span>Ambiente misto</span> O Ambiente Misto da Meile é
                      uma abordagem inovadora que combina o melhor dos serviços
                      de e-mail, como Google Workspace e Microsoft 365, com
                      nossa solução acessível e integrada.
                    </Link>
                  </>
                }
              />
            </div>

            <div className="box">
              <Reveal
                delay="0.4"
                component={
                  <>
                    <svg
                      width="22"
                      height="20"
                      viewBox="0 0 22 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.40221 19.2505L0.127197 17.2086L15.7272 3.20871H1.75221V0.292053H21.2523V17.7921H18.0022V5.25037L2.40221 19.2505Z"
                        fill="white"
                      />
                    </svg>
                    <span>Meile box</span>
                    Melhore o desempenho do seu negócio com a nossa plataforma
                    colaborativa, ideal para o compartilhamento de arquivos,
                    contatos, calendários, tarefas e muito mais!
                  </>
                }
              />
            </div>

            <div className="box">
              <Reveal
                delay="0.4"
                component={
                  <>
                    <svg
                      width="22"
                      height="20"
                      viewBox="0 0 22 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.40221 19.2505L0.127197 17.2086L15.7272 3.20871H1.75221V0.292053H21.2523V17.7921H18.0022V5.25037L2.40221 19.2505Z"
                        fill="white"
                      />
                    </svg>
                    <span>meile antispam</span> Garanta 99,9% de eficácia na
                    proteção contra spams, fraudes e phishing (roubo de
                    informações). realizamos as filtragens dos seus e-mails e
                    entregamos as mensagens ao seu servidor local.
                  </>
                }
              />
            </div>

            <div className="box">
              <Reveal
                delay="0.4"
                component={
                  <>
                    <svg
                      width="22"
                      height="20"
                      viewBox="0 0 22 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.40221 19.2505L0.127197 17.2086L15.7272 3.20871H1.75221V0.292053H21.2523V17.7921H18.0022V5.25037L2.40221 19.2505Z"
                        fill="white"
                      />
                    </svg>
                    <span>Meile MKT</span>
                    Divulgue, venda mais, engaje e crie fluxos de recuperação de
                    vendas com modelos de e-mails simples de serem
                    personalizados.
                  </>
                }
              />
            </div>

            <div className="box">
              <Reveal
                delay="0.4"
                component={
                  <>
                    <svg
                      width="22"
                      height="20"
                      viewBox="0 0 22 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.40221 19.2505L0.127197 17.2086L15.7272 3.20871H1.75221V0.292053H21.2523V17.7921H18.0022V5.25037L2.40221 19.2505Z"
                        fill="white"
                      />
                    </svg>
                    <span>meile SMTP</span> Envie com facilidade todos os seus
                    e-mails importantes, como notas fiscais, boletos,
                    notificações de softwares ERP e outros.
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Solutions;
