import React from "react";
import "./style.css";
import Reveal from "../Reveal";
import icon1 from "../../Assets/icons/spam 1.svg";
import icon2 from "../../Assets/icons/controle-de-acesso 1.svg";
import icon3 from "../../Assets/icons/pasta-de-arquivo 1.svg";
import icon4 from "../../Assets/icons/copia-de-seguranca 1.svg";
import icon5 from "../../Assets/icons/recibos 1.svg";
function SolutionsMail() {
  return (
    <div className="mail_solution_wrapper">
      <div className="mail_solution_container">
        <div className="mail_solution_headline_wrapper">
          <Reveal delay="0.6" component={<h1>Nossas soluções</h1>}></Reveal>
        </div>

        <div className="products_wrapper">
          <div className="box_products">
            <div className="box">
              <Reveal
                delay="0.6"
                component={
                  <Reveal
                    delay="0.6"
                    component={
                      <div className="box_icon">
                        <img src={icon1} alt="icons" />
                      </div>
                    }
                  ></Reveal>
                }
              ></Reveal>
              <div className="info_box_container">
                <Reveal
                  delay="0.6"
                  component={
                    <>
                      <span>MAV Antispam</span>

                      <p>
                        Garanta 99,9% de eficácia na proteção contra spams,
                        fraudes e phishing (roubo de informações) que chegam por
                        e-mail diariamente. Para isso, são feitas análises de
                        textos do e-mail, black list e assinaturas geradas
                        automaticamente. O bloqueio desses spams além de
                        garantir a segurança também aumenta a produtividade,
                        evitando perda de tempo com e-mails que não são o foco
                        do seu negócio.
                      </p>
                    </>
                  }
                ></Reveal>
              </div>
            </div>

            <div className="box">
              <Reveal
                delay="0.6"
                component={
                  <div className="box_icon">
                    <img src={icon2} alt="icons" />
                  </div>
                }
              ></Reveal>
              <div className="info_box_container">
                <Reveal
                  delay="0.6"
                  component={
                    <>
                      <span>Controle De Acesso</span>

                      <p>
                        Evite acessos inesperados que podem gerar horas extras
                        de seus colaboradores ou aumentar o risco de vazamentos
                        de informações confidenciais. Basta bloquear o acesso
                        aos e-mails fora do horário comercial ou da rede da
                        empresa.
                      </p>
                    </>
                  }
                ></Reveal>
              </div>
            </div>

            <div className="box">
              <Reveal
                delay="0.6"
                component={
                  <div className="box_icon">
                    <img src={icon3} alt="icons" />
                  </div>
                }
              ></Reveal>
              <div className="info_box_container">
                <Reveal
                  delay="0.6"
                  component={
                    <>
                      <span>Arquivamento</span>{" "}
                      <p>
                        Acesse o registro de todo o tráfego e conteúdo dos seus
                        e-mails. Além do melhor monitoramento dos dados, é
                        possível recuperar mensagens já excluídas pelos usuários
                        e fazer o arquivamento, ou seja, a documentação dessas
                        informações.
                      </p>
                    </>
                  }
                ></Reveal>
              </div>
            </div>

            <div className="box">
              <Reveal
                delay="0.6"
                component={
                  <div className="box_icon">
                    <img src={icon4} alt="icons" />
                  </div>
                }
              ></Reveal>
              <div className="info_box_container">
                <Reveal
                  delay="0.6"
                  component={
                    <>
                      <span>Backup</span>{" "}
                      <p>
                        Tenha cópias de segurança de todos os e-mails que
                        circulam na empresa e recupere qualquer mensagem, de
                        maneira imediata. Esses e-mails não podem ser alterados
                        ou apagados e ficam armazenados até 10 anos.
                      </p>
                    </>
                  }
                ></Reveal>
              </div>
            </div>

            <div className="box">
              <Reveal
                delay="0.6"
                component={
                  <div className="box_icon">
                    <img src={icon5} alt="icons" />
                  </div>
                }
              ></Reveal>
              <div className="info_box_container">
                <Reveal
                  delay="0.6"
                  component={
                    <>
                      <span>Recibo De Entrega</span>{" "}
                      <p>
                        Identifique problemas no envio de e-mails e evite falhas
                        na comunicação com seu cliente. Disponibilizamos
                        relatórios para que você cheque se um e-mail saiu da sua
                        caixa de saída ou parou no servidor do destinatário.
                      </p>
                    </>
                  }
                ></Reveal>
              </div>
            </div>
          </div>
        </div>
        <div className="info_bottom_solutions">
          <Reveal
            delay="0.6"
            component={
              <>
                <p>
                  Explore novos horizontes para o seu negócio e desbloqueie o
                  potencial extraordinário da comunicação corporativa. Com nossa
                  hospedagem profissional de e-mail, você tem a garantia de
                  segurança, confiabilidade e inovação.
                </p>
              </>
            }
          ></Reveal>
          <Reveal
            delay="0.6"
            component={<h1>Pronto para elevar sua comunicação empresarial?</h1>}
          ></Reveal>
          <Reveal
            delay="0.6"
            component={
              <>
                <p>
                  Junte-se a grandes empresas que confiam no Meile Mail. Entre
                  em contato conosco hoje mesmo para saber mais e dar o próximo
                  passo em direção a uma comunicação mais eficiente e segura.
                </p>
              </>
            }
          ></Reveal>
        </div>
      </div>
    </div>
  );
}

export default SolutionsMail;
